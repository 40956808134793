<template>
  <div>
    <b-row>
      <b-col lg="4">
        <h4 class="h5 font-weight-bold">Add New Tag</h4>
        <MainForm v-on:submit="submit" />
      </b-col>
      <b-col lg="8">
        <b-row class="justify-content-end">
          <b-col cols="5" lg="6" class="pr-lg-2 mb-3 mb-lg-0">
            <b-button v-show="selectedTags.length" @click.prevent="showDeleteConfirm(selectedTags)" size="sm" variant="outline-danger"><i class="fe fe-trash"></i> Delete Selected</b-button>
          </b-col>
          <b-col lg="6" class="text-right">
            <a-form :form="form"  @submit.prevent="search" layout="inline">
              <a-input-search v-model="q" placeholder="Search Tags"></a-input-search>
            </a-form>
          </b-col>
        </b-row>
        <div class="py-1 px-3 mt-3 card">
          <a-table
            :row-selection="{ selectedRowKeys: selectedTags, onChange: onSelectChange }"
            :scroll="{ x: 700 }"
            :loading="loading"
            :columns="columns"
            :dataSource="tags"
            :class="$style.table"
            :pagination="pagination"
            @change="tableChanged">
            <template slot="name" slot-scope="name, item">
              <h5>
                <router-link title="Edit" :to="{ path: `tags/edit/${item.id}`}">
                  {{ name }} ({{item.__meta__['posts_count']}})
                </router-link>
              </h5>
            </template>
            <template slot="slug" slot-scope="slug">
              {{ `${portalData.url}/tag/${slug}` }}
            </template>
            <template slot="created_at" slot-scope="created_at">
              {{ created_at | moment("D MMM YYYY, h:mm A") }}
            </template>
            <template slot="action" slot-scope="action, item">
              <div>
                <router-link title="Edit" :to="{ path: `tags/edit/${item.id}`}" class="btn btn-sm btn-primary mr-2">
                  <i class="fe fe-edit" />
                </router-link>
                <button title="Delete" @click="showDeleteConfirm([item.id])" type="button" class="btn btn-sm btn-light">
                  <i class="fe fe-trash" />
                </button>
              </div>
            </template>
          </a-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'

import MainForm from './form'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '30%',
    className: 'text-gray-6',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    className: 'text-gray-6',
    scopedSlots: { customRender: 'slug' },
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    className: 'text-gray-6',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    dataIndex: 'action',
    className: 'bg-transparent text-right',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
    width: 100,
  },
]
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      pagination: {},
      columns,
      q: '',
      selectedTags: [],
    }
  },
  created() {
    const vm = this
    vm.fetch()
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('portal', {
      portalData: state => state.form,
    }),
    ...mapState('tag', {
      tags: state => state.data,
    }),
    loading() {
      return this.$store.state.tag.loading
    },
  },
  methods: {
    search() {
      const vm = this
      vm.fetch()
    },
    fetch(payload = {}) {
      const vm = this
      vm.$store.dispatch('tag/FETCH', {
        portal_id: vm.settings.activePortal,
        q: vm.q,
        ...payload,
      }).then((res) => {
        const pagination = {
          ...vm.pagination,
          current: Number(res.data.page),
          total: Number(res.data.total),
        }
        vm.pagination = pagination
      })
    },
    onSelectChange(selectedTags) {
      this.selectedTags = selectedTags
    },
    showDeleteConfirm(ids) {
      const vm = this

      Modal.confirm({
        title: 'Are you sure delete this tag?',
        content: 'This tag will deleted permanently',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          ids.forEach((id) => {
            vm.$store.dispatch('tag/DELETE', id).then((res) => {
              vm.$notification.success({
                message: 'Tag Deleted',
                description: 'Successfully deleted.',
              })
              const index = vm.selectedTags.indexOf(id)
              if (index > -1) {
                vm.selectedTags.splice(index, 1)
              }
              vm.fetch()
            })
          })
        },
        onCancel() {},
      })
    },
    tableChanged(pagination, filters, sorter) {
      const vm = this
      vm.fetch({
        page: pagination.current,
        perPage: pagination.pageSize,
      })
    },
    submit() {
      const vm = this
      vm.$store.dispatch('tag/STORE').then((res) => {
        vm.fetch()
        vm.$store.commit('tag/CLEAR_FORM')
      })
    },
  },
  watch: {
    'settings.activePortal': function() {
      const vm = this
      vm.fetch()
    },
  },
  components: {
    MainForm,
  },
}
</script>
<style lang="scss" module>
.table {
  td {
    vertical-align: top;

    h5 {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
}
</style>
